<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueJsonToCsv from "vue-json-to-csv";

// import axios from "axios";
/**
 * Form validation component
 */
export default {
    page: {
        title: "TAXES",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, ckeditor: CKEditor.component, VueJsonToCsv, },
    data() {
        return {
            title: "Taxes et impots",
            tmpCoverFile: null,
            file: null,
            fileModif: null,
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "Taxes",
                    active: true,
                },
            ],
            adsForm: {
                title: "",
                text: "",
                link: "",
                phone: "",
                phoneCode: "",
                rayon: "",
                endAt: "",
                startAt: "",
                img: "",
                lieu: "",
                lieuLng: "",
                lieuLat: "",
            },
            adsFormModif: {
                id: "",
                title: "",
                text: "",
                link: "",
                phone: "",
                phoneCode: "",
                rayon: "",
                endAt: "",
                startAt: "",
                img: "",
                lieu: "",
                lieuLng: "",
                lieuLat: "",
            },


            totalRows: 1,
            currentPage: 1,
            perPage: 100,
            pageOptions: [100, 250, 500],
            filter: null,
            tvaData: [],
            tvaDataLocation: [],
            tvaDataVtc: [],
            activeAds: [],
            disabledAds: [],
            filterOn: [],
            sortBy: "orderid",
            sortDesc: false,
            fields: [
                { key: "client", sortable: true, label: "Nom et prénom" },
                { key: "date", sortable: true, label: "Date de paiement" },
                { key: "commission", sortable: true, label: "Commision" },
                { key: "tva", sortable: true, label: "Valeur TVA" },

                { key: "action", sortable: true, label: "Action" },

            ],
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            showDelete: false,
            showEdit: false,
            showStat: false,
            showdisable: false,
            showactive: false,
            editor: ClassicEditor,
            statData: []


        };
    },

    mounted() {
        this.init();
        this.initLocation();
        this.initVtc()
    },

    methods: {

        // GET /tva-vtc
        // GET /tva-location
        // GET /tva-covoiturage
        async init() {
            const tvaCovoiturage = await apiRequest("GET", "tva-covoiturage", undefined, false);
            console.log("tvaCovoiturage", tvaCovoiturage)
            if (tvaCovoiturage && tvaCovoiturage.data) {
                const tva = tvaCovoiturage.data.map((data) => {
                    return {
                        client: data.client !== undefined ? data.client.personne.firstname + " " + data.client.personne.lastname : " ",
                        id: data.id,

                        montant: data.montant,
                        commission: data.commission !== undefined ? data.commission : "0",
                        tva: data.tva_preleve,
                        date: new Date(data.datePaiement).toLocaleDateString("fr-FR"),
                        // startAt: new Date(data.startAt).toLocaleDateString("fr-FR"),
                        // endAt: new Date(data.endAt).toLocaleDateString("fr-FR"),
                        // adminId:
                        //     data.admin != null
                        //         ? data.admin.firstname + " " + data.admin.lastnameate
                        //         : "",
                    };
                });
                this.tvaData = tva;
                console.log("tva Data", this.tvaData)
                // this.disabledAds = [];
                // this.activeAds = [];


            }
        },
        async initLocation() {
            const tvaLocation = await apiRequest("GET", "tva-location", undefined, false);
            console.log("tvaLocation", tvaLocation)
            if (tvaLocation && tvaLocation.data) {
                const tva = tvaLocation.data.map((data) => {
                    return {
                        client: data.client !== undefined ? data.client.personne.firstname + " " + data.client.personne.lastname : " ",
                        id: data.id,

                        montant: data.montant,
                        commission: data.commission !== undefined ? data.commission : "0",
                        tva: data.tva_preleve,
                        date: new Date(data.datePaiement).toLocaleDateString("fr-FR"),
                        // startAt: new Date(data.startAt).toLocaleDateString("fr-FR"),
                        // endAt: new Date(data.endAt).toLocaleDateString("fr-FR"),
                        // adminId:
                        //     data.admin != null
                        //         ? data.admin.firstname + " " + data.admin.lastnameate
                        //         : "",
                    };
                });
                this.tvaDataLocation = tva;
                console.log("tva Data", this.tvaDataLocation)
                // this.disabledAds = [];
                // this.activeAds = [];


            }
        },
        async initVtc() {
            const tvaVtc = await apiRequest("GET", "tva-vtc", undefined, false);
            console.log("tvaVtc", tvaVtc)
            if (tvaVtc && tvaVtc.data) {
                const tva = tvaVtc.data.map((data) => {
                    return {
                        client: data.client !== undefined && data.client !== null ? data.client.personne.firstname + " " + data.client.personne.lastname : " Zémitcha ",
                        id: data.id,

                        montant: data.montant,
                        commission: data.commission !== undefined && data.commission !== null ? data.commission : "0",
                        tva: data.tva_preleve,
                        date: new Date(data.datePaiement).toLocaleDateString("fr-FR"),
                        // startAt: new Date(data.startAt).toLocaleDateString("fr-FR"),
                        // endAt: new Date(data.endAt).toLocaleDateString("fr-FR"),
                        // adminId:
                        //     data.admin != null
                        //         ? data.admin.firstname + " " + data.admin.lastnameate
                        //         : "",
                    };
                });
                this.tvaDataVtc = tva;
                //console.log("tva Data",this.tvaDataVtc)
                // this.disabledAds = [];
                // this.activeAds = [];


            }
        },



    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="card-title">
                            <h5></h5>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <b-card header-class="bg-transparent border-primary" class="border border-primary">
                                    <template v-slot:header>
                                        <h5 class="my-0 mb-4">Covoiturage</h5>
                                    </template>
                                    <div class="float-right">
                                        <h1 class="text-primary">{{ 0 }}</h1>
                                    </div>
                                </b-card>
                            </div>

                            <div class="col-md-4">
                                <b-card header-class="bg-transparent border-info" class="border border-info">
                                    <template v-slot:header>
                                        <h5 class="my-0 mb-4">VTC</h5>
                                    </template>
                                    <div class="float-right">
                                        <h1 class="text-primary">{{ 0 }}</h1>
                                    </div>
                                </b-card>
                            </div>
                            <div class="col-md-4">
                                <b-card header-class="bg-transparent border-success" class="border border-success">
                                    <template v-slot:header>
                                        <h5 class="my-0 mb-4">Location</h5>
                                    </template>
                                    <div class="float-right">
                                        <h1 class="text-primary">{{ 0 }}</h1>
                                    </div>
                                </b-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="card-title">
                            <h5>Taxes</h5>
                        </div>
                        <form>
                            <b-tabs nav-class="nav-tabs-custom">
                                <b-tab title-link-class="p-3">
                                    <template v-slot:title>
                                        <a class="font-weight-bold active">Covoiturage</a>
                                    </template>
                                    <div class="row mt-4">
                                        <div class="col-sm-12 col-md-4">
                                            <div id="tickets-table_length" class="dataTables_length">
                                                <label class="d-inline-flex align-items-center">
                                                    Afficher&nbsp;
                                                    <b-form-select v-model="perPage" size="sm"
                                                        :options="pageOptions"></b-form-select>&nbsp;entrées
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <vue-json-to-csv :json-data="tvaData" :csv-title="'assurance'"
                                                :separator="';'" @success="(val) => handleSuccess(val)"
                                                @error="(val) => handleError(val)">
                                                <button type="button" class="teal button__custom">
                                                    <b>Exporter en csv</b>
                                                </button>
                                            </vue-json-to-csv>
                                        </div>
                                        <!-- Search -->
                                        <div class="col-sm-12 col-md-4">
                                            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                                <label class="d-inline-flex align-items-center">
                                                    Rechercher:
                                                    <b-form-input v-model="filter" type="search"
                                                        class="form-control form-control-sm ml-2"></b-form-input>
                                                </label>
                                            </div>
                                        </div>
                                        <!-- End search -->
                                    </div>
                                    <div class="table-responsive">
                                        <b-table class="table-centered" :items="tvaData" :fields="fields"
                                            responsive="sm" :per-page="perPage" :current-page="currentPage"
                                            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                                            :filter-included-fields="filterOn" @filtered="onFiltered">
                                            <template v-slot:cell(action)="row">
                                                <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover
                                                    @click="editRow(row.item)" title="Editer">
                                                    <i class="mdi mdi-pencil font-size-18"></i>
                                                </a>

                                                <a v-if="row.item.active" href="javascript:void(0);"
                                                    :class="{ 'text-danger': row.item.active }" v-b-tooltip.hover
                                                    @click="desactiverRow(row.item)" title="Désactiver">
                                                    <i class="mdi mdi-close font-size-18"></i>
                                                </a>
                                                <a href="javascript:void(0);"
                                                    :class="{ 'text-success': !row.item.active }" v-b-tooltip.hover
                                                    @click="getStat(row.item.id)" title="Voir">
                                                    <i class="mdi mdi-eye font-size-18"></i>
                                                </a>
                                                <a v-if="!row.item.active" href="javascript:void(0);"
                                                    :class="{ 'text-success': !row.item.active }" v-b-tooltip.hover
                                                    @click="activerRow(row.item)" title="Activer">
                                                    <i class="mdi mdi-check font-size-18"></i>
                                                </a>
                                                <a href="javascript:void(0);" class="text-danger"
                                                    @click="deleteRow(row.item)" v-b-tooltip.hover title="supprimer">
                                                    <i class="mdi mdi-trash-can font-size-18"></i>
                                                </a>
                                            </template>
                                        </b-table>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                                <ul class="pagination pagination-rounded mb-0">
                                                    <!-- pagination -->
                                                    <b-pagination v-model="currentPage" :total-rows="tvaData.length"
                                                        :per-page="perPage" :limit="1" first-text="◀" last-text="▶"
                                                        aria-controls="my-table">
                                                    </b-pagination>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- End search -->
                                    </div>
                                </b-tab>
                                <b-tab title-link-class="p-3">
                                    <template v-slot:title>
                                        <a class="font-weight-bold active">VTC</a>
                                    </template>
                                    <div class="row mt-4">
                                        <div class="col-sm-12 col-md-4">
                                            <div id="tickets-table_length" class="dataTables_length">
                                                <label class="d-inline-flex align-items-center">
                                                    Afficher&nbsp;
                                                    <b-form-select v-model="perPage" size="sm"
                                                        :options="pageOptions"></b-form-select>&nbsp;entrées
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <vue-json-to-csv :json-data="tvaDataVtc" :csv-title="'assurance'"
                                                :separator="';'" @success="(val) => handleSuccess(val)"
                                                @error="(val) => handleError(val)">
                                                <button type="button" class="teal button__custom">
                                                    <b>Exporter en csv</b>
                                                </button>
                                            </vue-json-to-csv>
                                        </div>
                                        <!-- Search -->
                                        <div class="col-sm-12 col-md-4">
                                            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                                <label class="d-inline-flex align-items-center">
                                                    Rechercher:
                                                    <b-form-input v-model="filter" type="search"
                                                        class="form-control form-control-sm ml-2"></b-form-input>
                                                </label>
                                            </div>
                                        </div>
                                        <!-- End search -->
                                    </div>
                                    <div class="table-responsive">
                                        <b-table class="table-centered" :items="tvaDataVtc" :fields="fields"
                                            responsive="sm" :per-page="perPage" :current-page="currentPage"
                                            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                                            :filter-included-fields="filterOn" @filtered="onFiltered">
                                            <template v-slot:cell(action)="row">
                                                <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover
                                                    @click="editRow(row.item)" title="Editer">
                                                    <i class="mdi mdi-pencil font-size-18"></i>
                                                </a>

                                                <a v-if="row.item.active" href="javascript:void(0);"
                                                    :class="{ 'text-danger': row.item.active }" v-b-tooltip.hover
                                                    @click="desactiverRow(row.item)" title="Désactiver">
                                                    <i class="mdi mdi-close font-size-18"></i>
                                                </a>
                                                <a href="javascript:void(0);"
                                                    :class="{ 'text-success': !row.item.active }" v-b-tooltip.hover
                                                    @click="getStat(row.item.id)" title="Voir">
                                                    <i class="mdi mdi-eye font-size-18"></i>
                                                </a>
                                                <a v-if="!row.item.active" href="javascript:void(0);"
                                                    :class="{ 'text-success': !row.item.active }" v-b-tooltip.hover
                                                    @click="activerRow(row.item)" title="Activer">
                                                    <i class="mdi mdi-check font-size-18"></i>
                                                </a>
                                                <a href="javascript:void(0);" class="text-danger"
                                                    @click="deleteRow(row.item)" v-b-tooltip.hover title="supprimer">
                                                    <i class="mdi mdi-trash-can font-size-18"></i>
                                                </a>
                                            </template>
                                        </b-table>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                                <ul class="pagination pagination-rounded mb-0">
                                                    <!-- pagination -->
                                                    <b-pagination v-model="currentPage" :total-rows="tvaData.length"
                                                        :per-page="perPage" :limit="1" first-text="◀" last-text="▶"
                                                        aria-controls="my-table">
                                                    </b-pagination>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- End search -->
                                    </div>
                                </b-tab>
                                <b-tab title-link-class="p-3">
                                    <template v-slot:title>
                                        <a class="font-weight-bold active">Location</a>
                                    </template>
                                    <div class="row mt-4">
                                        <div class="col-sm-12 col-md-4">
                                            <div id="tickets-table_length" class="dataTables_length">
                                                <label class="d-inline-flex align-items-center">
                                                    Afficher&nbsp;
                                                    <b-form-select v-model="perPage" size="sm"
                                                        :options="pageOptions"></b-form-select>&nbsp;entrées
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <vue-json-to-csv :json-data="tvaDataLocation" :csv-title="'assurance'"
                                                :separator="';'" @success="(val) => handleSuccess(val)"
                                                @error="(val) => handleError(val)">
                                                <button type="button" class="teal button__custom">
                                                    <b>Exporter en csv</b>
                                                </button>
                                            </vue-json-to-csv>
                                        </div>
                                        <!-- Search -->
                                        <div class="col-sm-12 col-md-4">
                                            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                                <label class="d-inline-flex align-items-center">
                                                    Rechercher:
                                                    <b-form-input v-model="filter" type="search"
                                                        class="form-control form-control-sm ml-2"></b-form-input>
                                                </label>
                                            </div>
                                        </div>
                                        <!-- End search -->
                                    </div>
                                    <div class="table-responsive">
                                        <b-table class="table-centered" :items="tvaDataLocation" :fields="fields"
                                            responsive="sm" :per-page="perPage" :current-page="currentPage"
                                            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                                            :filter-included-fields="filterOn" @filtered="onFiltered">
                                            <template v-slot:cell(action)="row">
                                                <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover
                                                    @click="editRow(row.item)" title="Editer">
                                                    <i class="mdi mdi-pencil font-size-18"></i>
                                                </a>

                                                <a v-if="row.item.active" href="javascript:void(0);"
                                                    :class="{ 'text-danger': row.item.active }" v-b-tooltip.hover
                                                    @click="desactiverRow(row.item)" title="Désactiver">
                                                    <i class="mdi mdi-close font-size-18"></i>
                                                </a>
                                                <a href="javascript:void(0);"
                                                    :class="{ 'text-success': !row.item.active }" v-b-tooltip.hover
                                                    @click="getStat(row.item.id)" title="Voir">
                                                    <i class="mdi mdi-eye font-size-18"></i>
                                                </a>
                                                <a v-if="!row.item.active" href="javascript:void(0);"
                                                    :class="{ 'text-success': !row.item.active }" v-b-tooltip.hover
                                                    @click="activerRow(row.item)" title="Activer">
                                                    <i class="mdi mdi-check font-size-18"></i>
                                                </a>
                                                <a href="javascript:void(0);" class="text-danger"
                                                    @click="deleteRow(row.item)" v-b-tooltip.hover title="supprimer">
                                                    <i class="mdi mdi-trash-can font-size-18"></i>
                                                </a>
                                            </template>
                                        </b-table>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                                <ul class="pagination pagination-rounded mb-0">
                                                    <!-- pagination -->
                                                    <b-pagination v-model="currentPage" :total-rows="tvaData.length"
                                                        :per-page="perPage" :limit="1" first-text="◀" last-text="▶"
                                                        aria-controls="my-table">
                                                    </b-pagination>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- End search -->
                                    </div>
                                </b-tab>

                            </b-tabs>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-backdrop" centered v-model="showDelete"
            title="Voulez-vous vraiment supprimer cette publicité  ?" title-class="font-18" hide-footer>
            <div class="row float-right mt-3">
                <b-button variant="danger" @click="showDelete = false">Non</b-button>
                <b-button variant="success" @click="deleteAds()" class="ml-3 mr-4">Oui</b-button>
            </div>
        </b-modal>

        <b-modal id="modal-backdrop" centered v-model="showdisable"
            title="Voulez-vous vraiment désactiver cette publicité  ?" title-class="font-18" hide-footer>
            <div class="row float-right mt-3">
                <b-button variant="danger" @click="showdisable = false">Non</b-button>
                <b-button variant="success" @click="disableAds()" class="ml-3 mr-4">Oui</b-button>
            </div>
        </b-modal>

        <b-modal id="modal-backdrop" centered v-model="showactive"
            title="Voulez-vous vraiment activer cette publicité ?" title-class="font-18" hide-footer>
            <div class="row float-right mt-3">
                <b-button variant="danger" @click="showactive = false">Non</b-button>
                <b-button variant="success" @click="enableAds()" class="ml-3 mr-4">Oui</b-button>
            </div>
        </b-modal>

        <b-modal id="modal-center" centered size="xl" v-model="showEdit" title="Editer utilisateur"
            title-class="font-18" hide-footer>
            <form enctype="multipart/form-data" @submit.prevent="updatePub">
                <div class="form-group">
                    <label for="tite">Titre</label>
                    <input type="text" id="title" class="form-control" placeholder="titre"
                        v-model="adsFormModif.title" />
                </div>
                <div class="form-group">
                    <label for="tite">Contenu</label>

                    <ckeditor v-model="adsFormModif.text" :editor="editor" aria-placeholder="Sous-title">
                    </ckeditor>
                </div>
                <div class="row">

                    <div class=" col-md-6 form-group">
                        <label for="tite">Lien du bon data</label>
                        <input type="text" id="title" class="form-control" placeholder="Lien du bon data"
                            v-model="adsFormModif.link" />
                    </div>

                    <div class=" col-md-6 form-group ">

                        <template>
                            <label for="phone">Téléphone</label>
                            <vue-tel-input id="phone" v-model="adsFormModif.phone"
                                :inputOptions="{ placeholder: 'Numéro de téléphone' }" @country-changed="countryChanged"
                                @input="getPhone"></vue-tel-input>
                        </template>


                    </div>

                </div>

                <div class="row form-group">
                    <div class="col-md-6 form-group">
                        <label for="lieu">Lieu</label>
                        <gmap-autocomplete :value="adsFormModif.lieu" type="text" class="form-control" id="lieu"
                            placeholder="Lieu" @place_changed="changeAdress" :select-first-on-enter="true">
                        </gmap-autocomplete>

                    </div>
                    <div class=" col-md-6 form-group">
                        <label for="rayon">Rayon</label>
                        <input type="text" id="rayon" class="form-control" placeholder="Rayon de diffusion"
                            v-model="adsFormModif.rayon" />
                    </div>

                </div>
                <div class="row">

                    <div class=" col-md-6 form-group">
                        <label for="startAt">Début de la publicité</label>
                        <input type="date" id="startAt" class="form-control" v-model="adsFormModif.startAt" />
                    </div>

                    <div class="col-md-6 form-group">
                        <label for="endAT">Fin de la publicité</label>
                        <input type="date" id="endAT" class="form-control" v-model="adsFormModif.endAt" />
                    </div>

                </div>



                <fieldset>
                    <div class="form-group">
                        <label for="tite">Image de la publicité</label>
                        <input type="file" id="tite" ref="files" v-on:change="handleFileUploadModif"
                            class="form-control" />
                    </div>

                </fieldset>

                <div class="form-group">
                    <button class="btn btn-primary float-right">Ajouter</button>
                </div>
            </form>
        </b-modal>
        <b-modal id="modal-stat" centered size="lg" v-model="showStat" title="Détails de la publicité"
            title-class="font-18" hide-footer>

            <div class="row p-3 d-flex justify-content-center mx-auto">

                <div class="col-md-4  mx-2 ">
                    <div class="card p-3">

                        <div class="d-flex justify-content-between stat-detail">
                            <div class="title">
                                Nombre de vue
                            </div>
                            <div>
                                <div class="mb-3 icon text-danger">
                                    <i class='fas fa-eye'></i>
                                </div>


                                <div class="text-center data">

                                    <span>{{ statData.views }}</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mx-2 ">
                    <div class="card p-3">

                        <div class="d-flex justify-content-between stat-detail">
                            <div class="title">
                                Nombre de clic
                            </div>
                            <div>
                                <div class="mb-3 icon">
                                    <i class='fas fa-mouse-pointer text-warning'></i>
                                </div>


                                <div class="text-center data">

                                    <span>{{ statData.clics }}</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4  mx-2 ">
                    <div class="card p-3">

                        <div class="d-flex justify-content-between stat-detail">
                            <div class="title">
                                Bouton appel
                            </div>
                            <div>
                                <div class="mb-3 icon">
                                    <i class='fas fa-phone text-info'></i>
                                </div>


                                <div class="text-center data">

                                    <span>{{ statData.clics_appel }}</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4  mx-2">
                    <div class="card p-3">

                        <div class="d-flex justify-content-between stat-detail">
                            <div class="title">
                                Nombre de vue
                            </div>
                            <div>
                                <div class="mb-3 icon">
                                    <i class='fas fa-eye'></i>
                                </div>


                                <div class="text-center data">

                                    <span>3</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->


            </div>


        </b-modal>

    </Layout>
</template>

<style>
#modal-stat .modal-body {
    background-color: #dfb0ff42;
}

.stat-detail .title {
    font-size: large;
    font-weight: bolder;
}

.stat-detail .icon {
    font-size: large;
    font-weight: bolder;
}

.stat-detail .data {
    font-size: large;

}

.pac-container {
    z-index: 1051 !important;
}

#showPassword {
    width: 35px;
    height: 35px;
}

thead {
    background: #3aaa35;
}

thead div {
    color: #fff;
}

.input {
    right: 13px;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 200px;
    height: 35px;
    bottom: 0;
    flex: 60%;
    margin-bottom: 5px;
    box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
    background: #ffffff;
}

.input input {
    padding: 3px 3px 3px 8px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 18px;
    color: #495057;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
}

.input input:focus {
    border: 3px solid #3aaa35;
}

.inputCol {
    position: relative;
}

.paging_simple_numbers {
    background: #3aaa35;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    padding: 10px 30px;

    box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
        -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
    color: white !important;
}

.paging_simple_numbers ul li button {
    color: #fff !important;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #252b3b;
    border-color: #252b3b;
}


.nav-tabs .nav-link a {
    color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
    color: #fff;
    font-weight: 600 !important;
    vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
    font-weight: 600;
    background: #3AAA35;
    border-bottom: 1px solid #fff;
}

.nav-tabs-custom .nav-item .nav-link::after {
    border-bottom: 1px solid #000000;
}
.button__custom {
  color: var(--gray-0);
  background-color: #37b24d;
  height: 2.3rem;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  margin-bottom: 15px;
}
</style>
